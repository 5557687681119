const en = {
  title: 'My Software',
  snackbar: {
    updatesRetrieveError: 'Error while retrieving base product ids.',
    loading: 'Loading...'
  },
  download: 'Download',
  quickDownloads: 'Quick Downloads',
  allDownloads: 'All Downloads',
  expanse: 'Expanse',
  currentReleases: 'Current Releases',
  releaseNotes: 'Latest Release Notes',
  filter: 'Filter',
  clear: 'Clear',
  product: 'Product',
  itemNumber: 'Item Number',
  versionNumber: 'Version Number',
  releasePhase: 'Release Phase',
  launchPhase: 'Launch Phase',
  lastModStartDate: 'Last Modified Start Date',
  lastModEndDate: 'Last Modified End Date',
  apply: 'Apply',
  cancel: 'Cancel',
  version: 'Version',
  lastMod: 'Last Modified',
  releaseNotesExist: 'Download release notes for this update',
  releaseNotesNotExist: 'No release notes have been provided for this update',
  error: 'An error has occurred',
  getLatest: 'Get Latest',
  previousVersions: 'Other Versions',
  noDownloads: 'No quick downloads available. Check All Downloads or contact your representive for access.'
}
export { en }
