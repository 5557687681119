import { useMsal } from '@azure/msal-react'
import { MenuAppBar, useWtxLocalization, NotFoundPage } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import SoulApi from '../api/SoulApi'
import { env } from '../index.js'
import MainMenu from './MainMenu'
import QuickDownloads from './QuickDownloads'
import UpdatesList from './UpdatesList'
import { isSpecialSoftware } from '../utils/productTypeHash'
import version from '../env/version.json'

function MainPage() {
  const { instance, accounts } = useMsal()
  const [quickUpdates, setQuickUpdates] = useState([])
  const [updatesAll, setUpdatesAll] = useState([])
  const localizedStrings = useWtxLocalization()
  document.getElementById('title').innerHTML = localizedStrings.title

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ['updates'],
    queryFn: () => SoulApi.getMyUpdates(instance, accounts)
  })

  useEffect(() => {
    let updates = []
    let specialSoftware = []
    let updatesAllVersions = []

    if (data) {
      let updatesMap = data.reduce(
        function ([map, versions, special, arcSpecial], obj) {
          let result = isSpecialSoftware(obj.baseProductId)

          if (result.special) {
            if (result.name in special) {
              special[result.name].push({ ...obj, url: `${env.urls.updatesURL}/api/v1/downloads/mine/${obj.id}` })
            } else {
              if (obj.releasePhase === 'General') {
                special[result.name] = [{ ...obj, url: `${env.urls.updatesURL}/api/v1/downloads/mine/${obj.id}` }]
              }
            }
          } else if (result.name === 'CING') {
            // add to latest map
            if (Object.keys(arcSpecial).includes(obj.baseProductId)) {
              if (arcSpecial[obj.baseProductId].createdOn < obj.createdOn && obj.releasePhase === 'General') {
                arcSpecial[obj.baseProductId] = { ...obj, url: `${env.urls.updatesURL}/api/v1/downloads/mine/${obj.id}` }
              }
            } else {
              if (obj.releasePhase === 'General') {
                arcSpecial[obj.baseProductId] = { ...obj, url: `${env.urls.updatesURL}/api/v1/downloads/mine/${obj.id}` }
              }
            }

            //add to all versions
            if (Object.keys(versions).includes('CING')) {
              versions['CING'].push({ ...obj })
            } else {
              versions['CING'] = [{ ...obj }]
            }
          } else {
            // add to latest map
            if (Object.keys(map).includes(obj.baseProductId)) {
              if (map[obj.baseProductId].createdOn < obj.createdOn && obj.releasePhase === 'General') {
                map[obj.baseProductId] = { ...obj, url: `${env.urls.updatesURL}/api/v1/downloads/mine/${obj.id}` }
              }
            } else {
              if (obj.releasePhase === 'General') {
                map[obj.baseProductId] = { ...obj, url: `${env.urls.updatesURL}/api/v1/downloads/mine/${obj.id}` }
              }
            }

            //add to all versions
            if (Object.keys(versions).includes(obj.baseProductId)) {
              versions[obj.baseProductId].push({ ...obj })
            } else {
              versions[obj.baseProductId] = [{ ...obj }]
            }
          }

          return [map, versions, special, arcSpecial]
        },
        [{}, {}, {}, {}]
      )

      updates = Object.entries(updatesMap[0]).map(u => {
        return {
          baseProductId: u[0],
          update: u[1]
        }
      })

      updatesAllVersions = Object.entries(updatesMap[1]).map(u => {
        return {
          baseProductId: u[0],
          allVersions: u[1]
        }
      })

      specialSoftware = Object.entries(updatesMap[2]).map(u => {
        return {
          baseProductId: u[0],
          update: { id: u[0] },
          updates: u[1]
        }
      })

      let arcGeneralList = []
      //deal with special arc case
      if (updatesMap[3].CING) {
        arcGeneralList.push(updatesMap[3].CING)
      }

      if (updatesMap[3].ARC2) {
        arcGeneralList.push(updatesMap[3].ARC2)
      }

      if (arcGeneralList.length > 0) {
        updates.push({
          baseProductId: 'CING',
          updates: [...arcGeneralList],
          update: { ...arcGeneralList[0] }
        })
      }
    }
    setQuickUpdates([...updates, ...specialSoftware])
    setUpdatesAll(updatesAllVersions)
  }, [data])

  return (
    <>
      <MenuAppBar env={env} fromCompany={env.basicAuthentication.fromCompany} />
      <div className='content'>
        <BrowserRouter>
          <Switch>
            <Route path='/' exact>
              <Redirect to='/quick' />
            </Route>
            <Route
              path='/quick'
              render={() => (
                <div style={{ display: 'flex' }}>
                  <MainMenu />
                  <QuickDownloads
                    updates={quickUpdates}
                    allUpdates={updatesAll}
                    loading={isLoading}
                    error={error}
                    refetch={refetch}
                  />
                </div>
              )}
            />
            <Route
              path='/updates'
              render={() => (
                <div style={{ display: 'flex' }}>
                  <MainMenu />
                  <UpdatesList
                    updates={data}
                    loading={isLoading}
                    error={error}
                    refetch={refetch}
                    styles={{ paddingLeft: '15px' }}
                  />
                </div>
              )}
            />
            <Route path='/version' render={() => <div style={{ display: 'flex' }}>{version.version}</div>} />
            {/* This wildcard route needs to stay at the bottom */}
            <Route path='/*' component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </div>
    </>
  )
}

export default MainPage
