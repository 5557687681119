import { useMsal } from '@azure/msal-react'
import { CircularProgress, Box } from '@mui/material'
import { acquireAccessToken, CenteredDiv, ErrorMessage, parseJwt } from '@wavetronix/common-components'
import { useState } from 'react'
import { env } from '../index.js'
import PreviousVersionsList from './PreviousVersionsList'
import { useEffect } from 'react'
import { productTypeHash, productTypeOrder } from '../utils/productTypeHash'
import DownloadSection from './DownloadSection'

export default function QuickDownloads({ updates, allUpdates, loading, error, refetch }) {
  const { instance, accounts } = useMsal()
  const [downloadToken, setDownloadToken] = useState('')
  const [previousVersionsVisible, setPreviousVersionsVisible] = useState(false)
  const [previousVersions, setPreviousVersions] = useState([])
  const [softwareUpdates, setSoftwareUpdates] = useState([])
  const [firmwareUpdates, setFirmwareUpdates] = useState([])

  useEffect(() => {
    if (updates) {
      let software = updates
        .filter(u => productTypeHash(u.baseProductId))
        .sort((a, b) => (productTypeOrder(a.baseProductId) > productTypeOrder(b.baseProductId) ? 1 : -1))

      let firmware = updates
        .filter(u => !productTypeHash(u.baseProductId))
        .sort((a, b) => (productTypeOrder(a.baseProductId) > productTypeOrder(b.baseProductId) ? 1 : -1))

      setSoftwareUpdates(software)
      setFirmwareUpdates(firmware)
    }
  }, [updates])

  useEffect(() => {
    async function resetDownloadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setDownloadToken(token)
    }

    if (!downloadToken || parseJwt(downloadToken).exp < Date.now() / 1000) {
      console.log('resetting token')
      resetDownloadToken()
    }
  }, [accounts, instance, downloadToken])

  if (error) {
    return (
      <div style={{ width: '100%' }}>
        <CenteredDiv>
          <ErrorMessage error={error} />
        </CenteredDiv>
      </div>
    )
  }
  if (loading) {
    return (
      <div style={{ width: '100%' }}>
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      </div>
    )
  } else {
    return (
      <Box sx={{ width: '100%' }}>
        <PreviousVersionsList
          open={previousVersionsVisible}
          onClose={() => setPreviousVersionsVisible(false)}
          data={previousVersions ? previousVersions : []}
          isLoading={loading}
          error={error}
          refetch={refetch}
        />
        <DownloadSection
          title={'Software'}
          updates={softwareUpdates}
          allUpdates={allUpdates}
          downloadToken={downloadToken}
          setPreviousVersions={setPreviousVersions}
          setPreviousVersionsVisible={setPreviousVersionsVisible}
        />
        <DownloadSection
          title={'Firmware'}
          updates={firmwareUpdates}
          allUpdates={allUpdates}
          downloadToken={downloadToken}
          setPreviousVersions={setPreviousVersions}
          setPreviousVersionsVisible={setPreviousVersionsVisible}
        />
      </Box>
    )
  }
}
