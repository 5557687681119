import { acquireAccessToken, SnackbarVariants } from '@wavetronix/common-components'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { env } from '../index.js'

export default async function downloadNotes(msalInstance, accounts, id, version, enqueueSnackbar) {
  const baseUrl = env.urls.updatesURL
  const url = '/api/v1/releasenotes'
  const href = `${baseUrl}${url}/${id}`
  let token = await acquireAccessToken(msalInstance, accounts, env)
  axios({
    url: href,
    method: 'GET',
    responseType: 'blob',
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(response => {
      fileDownload(response.data, `${version}.pdf`, 'application/pdf')
    })
    .catch(e => {
      if (e.message === 'Request failed with status code 404') {
        enqueueSnackbar('No release notes have been uploaded for this update', SnackbarVariants.ERROR)
      } else {
        enqueueSnackbar(`An error occurred.`, SnackbarVariants.ERROR)
      }
    })
}
