import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { useState, useEffect } from 'react'
import { TextField } from '@mui/material'
import { CustomSelect, useWtxLocalization } from '@wavetronix/common-components'
import LaunchPhaseApi from '../../api/LaunchPhaseApi'
import SelectBaseProduct from '../selects/SelectBaseProduct'
import { getReleasePhase } from '../../utils/productTypeHash'

export const DEFAULT_UPDATES_FILTER = {
  baseProductId: '',
  itemNumber: '',
  version: '',
  releasePhase: 'All',
  launchPhase: 'All'
}

export const filterUpdates = (filter, updates) =>
  updates
    ? updates.filter(
        u =>
          (filter.baseProductId === '' || u.baseProductId === filter.baseProductId) &&
          u.itemNumber.toLowerCase().includes(filter.itemNumber.toLowerCase()) &&
          u.version.includes(filter.version) &&
          (filter.releasePhase === 'All' || getReleasePhase(u.releasePhase) === filter.releasePhase) &&
          (filter.launchPhase === 'All' || u.launchPhase === filter.launchPhase)
      )
    : []

export default function UpdatesFilterDrawer({ filter, setFilter, baseProductOptions, availableReleases }) {
  let localizedStrings = useWtxLocalization()
  const [showProductOption, setShowProductOptions] = useState(true)
  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))
  const { instance, accounts } = useMsal()

  const { data: phasesData } = useQuery({
    queryKey: ['phasesFilterQuery'],
    queryFn: async () => await LaunchPhaseApi.getAllLaunchPhases(instance, accounts)
  })

  useEffect(() => {
    if (baseProductOptions) {
      let optionsSet = new Set(baseProductOptions)
      let optionShown = optionsSet.size > 1 ? true : false
      setShowProductOptions(optionShown)
    }
  }, [baseProductOptions])

  return (
    <>
      <SelectBaseProduct
        onChange={e => setFilter(f => ({ ...f, baseProductId: e.target.value }))}
        value={filter.baseProductId}
        label={localizedStrings.product}
        size='small'
        style={{ width: '100%' }}
        baseProductOptions={baseProductOptions}
      />
      <TextField
        id='filterItemNumberTextField'
        label={localizedStrings.itemNumber}
        variant='outlined'
        size='small'
        style={{ width: '100%', marginTop: showProductOption ? '15px' : '0px' }}
        onChange={e => handleChange('itemNumber', e.target.value)}
        value={filter.itemNumber}
      />
      <TextField
        id='filterVersionTextField'
        label={localizedStrings.versionNumber}
        variant='outlined'
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
        onChange={e => handleChange('version', e.target.value)}
        value={filter.version}
      />
      <CustomSelect
        id='filterReleasePhaseSelect'
        size='small'
        style={{ width: '100%', marginTop: '15px' }}
        label={localizedStrings.releasePhase}
        value={filter.releasePhase}
        onChange={e => setFilter(f => ({ ...f, releasePhase: e.target.value }))}
        options={['All', ...availableReleases]}
      />
      <CustomSelect
        id='filterlaunchPhaseSelect'
        style={{ width: '100%', marginTop: '15px' }}
        label='Launch Phase'
        value={filter.launchPhase}
        onChange={e => {
          setFilter(f => ({ ...f, launchPhase: e.target.value }))
        }}
        options={phasesData ? ['All', ...phasesData] : ['All']}
      />
    </>
  )
}
