import { useMsal } from '@azure/msal-react'
import { IconButton } from '@mui/material'
import { PictureAsPdfOutlined } from '@mui/icons-material'
import {
  acquireAccessToken,
  CustomDataGrid,
  DownloadButton,
  parseJwt,
  useWtxLocalization,
  PersistantFilterDiv,
  HasAccess
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { env } from '../index.js'
import downloadNotes from '../utils/downloadNotes'
import exists from '../utils/exists'
import { Sorter } from '../utils/sorter'
import UpdatesFilterDrawer, { DEFAULT_UPDATES_FILTER, filterUpdates } from './drawers/UpdatesFilterDrawer'
import { getDeviceName, getReleasePhase } from '../utils/productTypeHash'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

function UpdatesList({ updates, loading, error, refetch, styles }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const [filter, setFilter] = useState(DEFAULT_UPDATES_FILTER)
  const [pageSize, setPageSize] = useState(100)
  const [downloadToken, setDownloadToken] = useState('')
  const [availableReleases, setAvailableReleases] = useState([])
  let localizedStrings = useWtxLocalization()

  useEffect(() => {
    if (updates) {
      let releases = new Set()
      for (let update of updates) {
        let phase = getReleasePhase(update.releasePhase)
        if (!releases.has(phase)) {
          releases.add(phase)
        }
      }
      setAvailableReleases(Array.from(releases).sort(Sorter.RELEASE))
    }
  }, [updates])

  useEffect(() => {
    async function resetDownloadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setDownloadToken(token)
    }

    if (!downloadToken || parseJwt(downloadToken).exp < Date.now() / 1000) {
      console.log('resetting token')
      resetDownloadToken()
    }
  }, [accounts, instance, downloadToken])

  const internalColumns = [
    {
      headerName: localizedStrings.product,
      field: 'baseProductId',
      valueGetter: item => getDeviceName(item.value),
      sortComparator: Sorter.DEFAULT,
      flex: 1,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.itemNumber,
      field: 'itemNumber',
      sortComparator: Sorter.DEFAULT,
      disableColumnMenu: true,
      flex: 1,
      filterable: false,
      hide: isSmallScreen
    },
    {
      headerName: localizedStrings.version,
      field: 'version',
      sortComparator: Sorter.DEFAULT,
      flex: 1,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.releasePhase,
      field: 'releasePhase',
      sortComparator: Sorter.DEFAULT,
      flex: 1,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive,
      valueGetter: item => getReleasePhase(item.row.releasePhase)
    },
    {
      headerName: localizedStrings.launchPhase,
      field: 'launchPhase',
      sortComparator: Sorter.DEFAULT,
      disableColumnMenu: true,
      flex: 1,
      filterable: false,
      hide: isSmallScreen
    },
    {
      headerName: localizedStrings.releaseNotes,
      field: 'releaseNotes',
      renderCell: data => (
        <IconButton
          id={`${data.id}DownloadNotesButton`}
          onClick={() => downloadNotes(instance, accounts, data.row.id, data.row.version, enqueueSnackbar)}
          style={{ margin: '5px' }}
          disabled={!exists(data.row.releaseNoteLastModified)}
          title={data.row.releaseNoteLastModified ? localizedStrings.releaseNotesExist : localizedStrings.releaseNotesNotExist}
          size='large'
        >
          <PictureAsPdfOutlined />
        </IconButton>
      ),
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.download,
      field: 'download',
      renderCell: data => {
        var downloadUrl = `${env.urls.updatesURL}/api/v1/downloads/mine/${data.id}`
        return <DownloadButton id={`${data.id}DownloadUpdateButton`} url={downloadUrl} token={downloadToken} variant='icon' />
      },
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    }
  ]

  const externalColumns = [
    {
      headerName: localizedStrings.product,
      field: 'baseProductId',
      valueGetter: item => getDeviceName(item.value),
      sortComparator: Sorter.DEFAULT,
      flex: 1,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.itemNumber,
      field: 'itemNumber',
      sortComparator: Sorter.DEFAULT,
      disableColumnMenu: true,
      flex: 1,
      filterable: false,
      hide: isSmallScreen
    },
    {
      headerName: localizedStrings.version,
      field: 'version',
      sortComparator: Sorter.DEFAULT,
      flex: 1,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.releasePhase,
      field: 'releasePhase',
      sortComparator: Sorter.DEFAULT,
      flex: 1,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive,
      valueGetter: item => getReleasePhase(item.row.releasePhase)
    },
    {
      headerName: localizedStrings.releaseNotes,
      field: 'releaseNotes',
      renderCell: data => (
        <IconButton
          id={`${data.id}DownloadNotesButton`}
          onClick={() => downloadNotes(instance, accounts, data.row.id, data.row.version, enqueueSnackbar)}
          style={{ margin: '5px' }}
          disabled={!exists(data.row.releaseNoteLastModified)}
          title={data.row.releaseNoteLastModified ? localizedStrings.releaseNotesExist : localizedStrings.releaseNotesNotExist}
          size='large'
        >
          <PictureAsPdfOutlined />
          {/* release notes */}
        </IconButton>
      ),
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.download,
      field: 'download',
      renderCell: data => {
        var downloadUrl = `${env.urls.updatesURL}/api/v1/downloads/mine/${data.id}`
        return <DownloadButton id={`${data.id}DownloadUpdateButton`} url={downloadUrl} token={downloadToken} variant='icon' />
      },
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    }
  ]

  if (error) return `${localizedStrings.error}: ${error.message}`

  return (
    <PersistantFilterDiv
      defaultOpen={largeScreenFeaturesActive}
      page={
        <div style={{ width: '100%', ...styles }}>
          <HasAccess
            env={env}
            allowedRoles={['Wavetronix Employee']}
            unauthorizedControl={
              <CustomDataGrid
                rows={updates ? filterUpdates(filter, updates) : []}
                autoHeight
                columns={externalColumns}
                pageSize={pageSize}
                onPageSizeChange={p => setPageSize(p)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                disableSelectionOnClick
                loading={loading}
                disableExtendRowFullWidth={true}
              />
            }
          >
            <CustomDataGrid
              rows={updates ? filterUpdates(filter, updates) : []}
              autoHeight
              columns={internalColumns}
              pageSize={pageSize}
              onPageSizeChange={p => setPageSize(p)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              disableSelectionOnClick
              loading={loading}
              disableExtendRowFullWidth={true}
            />
          </HasAccess>
        </div>
      }
      drawer={
        <UpdatesFilterDrawer
          filter={filter}
          setFilter={setFilter}
          baseProductOptions={updates ? updates.map(d => d.baseProductId) : []}
          availableReleases={availableReleases}
        />
      }
      resetFilter={() => setFilter(DEFAULT_UPDATES_FILTER)}
    />
  )
}

export default UpdatesList
