import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { getDeviceName } from '../../utils/productTypeHash'
import { useEffect, useState } from 'react'

export default function SelectBaseProduct({ style, required, onChange, value, label, baseProductOptions }) {
  const [deviceNames, setDeviceNames] = useState()

  useEffect(() => {
    var set = baseProductOptions ? new Set(baseProductOptions) : []
    setDeviceNames(Array.from(set))
  }, [baseProductOptions])

  return (
    <>
      {deviceNames && deviceNames.length > 1 ? (
        <FormControl style={style} required={required} variant='outlined' size='small'>
          <InputLabel id='selectBaseProductComponentLabel'>{label}</InputLabel>
          <Select
            id='selectBaseProductComponent'
            labelId='selectBaseProductComponentLabel'
            value={deviceNames ? value : ''}
            onChange={onChange}
            label={label}
          >
            {deviceNames
              ? deviceNames.map(id => (
                  <MenuItem id={`${id}MenuItem`} value={id} key={id}>
                    {getDeviceName(id)}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      ) : (
        <span />
      )}
    </>
  )
}
