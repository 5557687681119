import { acquireAccessToken } from '@wavetronix/common-components'
import axios from 'axios'
import { env } from '../index.js'

let instance = null

class SoulApi {
  getMyUpdates = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.updatesURL}/api/v1/updates/mine`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getLatest = async (msalInstance, accounts, itemNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.updatesURL}/api/v1/downloads/latest/${itemNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  downloadUpdate = async (msalInstance, accounts, updateId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    var form = new FormData()
    form.append('token', token)

    return axios({
      url: `${env.urls.updatesURL}/api/v1/downloads/mine/${updateId}`,
      method: 'POST',
      data: form,
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new SoulApi()
  }
  return instance
}

export default getInstance()
