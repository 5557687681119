import { getResourceUrl, RegularButton } from '@wavetronix/common-components'
import React from 'react'
import { env } from '../index.js'
import { getDeviceName, productTypeOrder } from '../utils/productTypeHash'
import { productImageHash } from '../utils/productTypeHash'

export default function DownloadSpecialCard({ special, classes, downloadToken }) {
  return (
    <>
      <div className={classes.imageContainer}>
        <img
          className={classes.imageStyle}
          alt={`${getDeviceName(special.baseProductId)}`}
          src={`${getResourceUrl(env.runMode)}/images/products/${productImageHash(special.baseProductId)}`}
        />
      </div>
      <div className={classes.infoBlockStyle}>
        <h4 className={classes.productName}>{getDeviceName(special.baseProductId)}</h4>
        {special.updates
          .sort((a, b) => (productTypeOrder(a.baseProductId) > productTypeOrder(b.baseProductId) ? 1 : -1))
          .map(update => {
            return (
              <div style={{ marginTop: 10 }} key={update.id}>
                <form action={update.url} method='post'>
                  <RegularButton
                    id={`${update.baseProductId}DownloadButton`}
                    type='submit'
                    name='token'
                    value={downloadToken}
                    className={classes.softwareButtonStyle}
                  >
                    <div
                      style={{
                        fontSize: '15px'
                      }}
                    >
                      {getDeviceName(update.baseProductId)}
                    </div>
                  </RegularButton>
                </form>
              </div>
            )
          })}
      </div>
    </>
  )
}
