const fr = {
  title: 'Mon Logiciel',
  snackbar: {
    updatesRetrieveError: 'Erreur de récupération des fichiers produits Ids.',
    loading: 'Chargement...'
  },
  download: 'Téléchargement',
  centralProcessor: 'Processeur Central',
  quickDownloads: 'Téléchargements Rapides',
  allDownloads: 'Tous les Téléchargements',
  expanse: 'Expanse',
  currentReleases: 'Versions Actuelles',
  releaseNotes: 'Dernières Notes de Version',
  filter: 'Filtrer',
  clear: 'Effacer',
  product: 'Produit',
  itemNumber: 'Numéro Article',
  versionNumber: 'Numéro Version',
  releasePhase: 'Phase Publication',
  launchPhase: 'Phase de Lancement',
  lastModStartDate: 'Dernière Date de Début',
  lastModEndDate: 'Dernière Date de Fin',
  apply: 'Appliquer',
  cancel: 'Annuler',
  version: 'Version',
  lastMod: 'Dernière Modification',
  releaseNotesExist: 'Télécharger les notes de cette version',
  releaseNotesNotExist: 'Aucune note disponible pour cette version',
  error: 'Une erreur est survenue',
  getLatest: 'Obtenir la Dernière',
  previousVersions: 'Versions Autres',
  noDownloads:
    'Aucun téléchargement rapide disponible. Vérifiez tous les téléchargements ou contactez votre représentant pour y accéder.'
}
export { fr }
