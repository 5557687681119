import { env, baseProductHash } from '../index.js'
import { Buffer } from 'buffer'
import axios from 'axios'

export async function generateProductHash() {
  let credentials = Buffer.from(`${env.basicAuthentication.updatesUserName}:${env.basicAuthentication.updatesPassword}`).toString(
    'base64'
  )

  var data = await axios
    .get(`${env.urls.updatesURL}/api/v1/masterfeatures/baseproductid/hash`, {
      headers: { Authorization: `Basic ${credentials}` },
      timeout: 30000
    })
    .then(res => res.data)
    .catch(error => {
      console.log('Error retrieving base product id hash map')
      console.log(error)

      return []
    })

  return data.reduce((map, value) => {
    map[value.id] = value
    return map
  }, {})
}

export function productTypeHash(product) {
  if (product in baseProductHash) {
    return baseProductHash[product].isSoftware
  } else {
    return false
  }
}

export function productTypeOrder(product) {
  if (product in baseProductHash) {
    return baseProductHash[product].order
  } else {
    return Number.MAX_SAFE_INTEGER
  }
}

export function getDeviceName(id) {
  if (id in baseProductHash) return baseProductHash[id].productHashName
  else return id
}

export function getReleasePhase(phase) {
  if (phase === 'BetaCandidate') return 'Beta Candidate'
  else return phase
}

export function productImageHash(product) {
  if (product in baseProductHash) {
    return baseProductHash[product].image
  } else {
    return 'default.png'
  }
}

export function isSpecialSoftware(id) {
  if (id === 'CLICKSUPERVISOR' || id === 'MSDOTNET1' || id === 'CLICKUPDATEUTILITY') {
    return {
      name: 'CLICKSUPERVISOR',
      special: true
    }
  } else if (id === 'USB2RS485') {
    return {
      name: 'Other',
      special: true
    }
  } else if (id === 'CING' || id === 'ARC2') {
    return {
      name: 'CING',
      special: false
    }
  } else {
    return {
      name: id,
      special: false
    }
  }
}
