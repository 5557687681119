import dayjs from 'dayjs'
// https://www.aleksandrhovhannisyan.com/blog/sorting-ant-design-tables/

/**
 * @param {string} dateA - a date, represented in string format
 * @param {string} dateB - a date, represented in string format
 */
const dateSort = (dateA, dateB) => dayjs(dateA).diff(dayjs(dateB))

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
const defaultSort = (a, b) => {
  if (a < b) return -1
  if (b < a) return 1
  return 0
}

const releasePhaseSort = (a, b) => {
  let map = {
    Unreleased: 0,
    Engineering: 1,
    'Beta Candidate': 2,
    Beta: 3,
    General: 4
  }
  if (map[a] < map[b]) return -1
  if (map[b] < map[a]) return 1
  return 0
}

export const Sorter = {
  DEFAULT: defaultSort,
  DATE: dateSort,
  RELEASE: releasePhaseSort
}
