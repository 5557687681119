import { Tab, Tabs } from '@mui/material'
import { useWtxLocalization, WtxColors } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const PATH_MAP = {
  quick: 0,
  updates: 1
}
const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    width: '210px'
  }
}

function MainMenu() {
  const [value, setValue] = useState(0)
  const history = useHistory()
  let localizedStrings = useWtxLocalization()
  const onChange = path => {
    if (window.location.pathname !== `/${path}`) {
      history.push(`/${path}`)
    }
    setValue(PATH_MAP[path])
  }
  useEffect(() => {
    if (window.location.pathname === '/quick') {
      setValue(0)
    } else if (window.location.pathname === '/updates') {
      setValue(1)
    }
  }, [])
  return (
    <Tabs
      value={value}
      TabIndicatorProps={{
        sx: {
          backgroundColor: WtxColors.IQ_BLUE
        }
      }}
      orientation='vertical'
      sx={classes.tabParent}
    >
      <Tab id='quickDownloadsTab' sx={classes.tab} label={localizedStrings.quickDownloads} onClick={() => onChange('quick')} />
      <Tab id='updatesTab' sx={classes.tab} label={localizedStrings.allDownloads} onClick={() => onChange('updates')} />
    </Tabs>
  )
}

export default MainMenu
