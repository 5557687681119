import { makeStyles } from 'tss-react/mui'
import { Divider } from '@mui/material'
import { CenteredDiv, useWtxLocalization } from '@wavetronix/common-components'
import { Responsive, WidthProvider } from 'react-grid-layout'
import DownloadCard from './DownloadCard'
import DownloadZipCard from './DownloadZipCard'
import DownloadSpecialCard from './DownloadSpecialCard'

const ReactGridLayout = WidthProvider(Responsive)
const generateLayout = (data, col) => {
  return data.map((d, index) => {
    return {
      x: index % col,
      y: 0,
      w: 1,
      h: 1,
      i: d.update.id
    }
  })
}

const useStyles = makeStyles()(() => ({
  productCardStyle: {
    height: 100,
    display: 'flex'
  },
  imageContainer: {
    maxWidth: '30%',
    minWidth: '30%',
    display: 'flex'
  },
  imageStyle: {
    maxHeight: '150px',
    minHeight: '30%',
    maxWidth: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  infoBlockStyle: {
    display: 'block',
    marginBottom: 'auto',
    marginTop: '15px',
    width: '100%',
    marginLeft: 30
  },
  productName: {
    fontSize: '22px',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  infoLink: {
    fontSize: '18px',
    color: '#4B7FDE',
    fontFamily: 'Proxima Nova',
    '&:hover': {
      fontWeight: 'bold',
      cursor: 'pointer'
    }
  },
  softwareButtonStyle: {
    backgroundColor: '#ffcb05',
    borderColor: 'transparent',
    display: 'block',
    borderRadius: '30px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#e8b800'
    }
  },
  releaseNotesButtonStyle: {
    backgroundColor: '#71aeff',
    color: 'white',
    borderColor: 'transparent',
    display: 'flex',
    width: '100%',
    marginTop: '10px',
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#659ce6'
    }
  }
}))
export default function DownloadSection({
  title,
  updates,
  allUpdates,
  downloadToken,
  setPreviousVersions,
  setPreviousVersionsVisible
}) {
  const { classes } = useStyles()
  const localizedStrings = useWtxLocalization()
  return (
    <>
      <h1 style={{ textAlign: 'center', fontSize: '26px' }}>{title}</h1>
      <CenteredDiv>
        <div style={{ width: '90%' }}>
          <Divider />
          {updates && updates.length > 0 ? (
            <ReactGridLayout
              className='layout'
              isDraggable={false}
              isResizable={false}
              rowHeight={250}
              margin={[100, 20]}
              cols={{ lg: 2, md: 1, sm: 1, xs: 1, xxs: 1 }}
              layouts={{
                lg: generateLayout(updates, 2),
                sm: generateLayout(updates, 1)
              }}
            >
              {updates.map(u => {
                if (u.baseProductId === 'Other' || u.baseProductId === 'CLICKSUPERVISOR') {
                  return (
                    <div className={classes.productCardStyle} key={u.update.id}>
                      <DownloadSpecialCard special={u} classes={classes} downloadToken={downloadToken} />
                    </div>
                  )
                } else if (u.baseProductId === 'CING') {
                  let updatesPrevious = allUpdates.filter(v => v.baseProductId === u.baseProductId)[0]
                  let hasPrevious = updatesPrevious && updatesPrevious.allVersions.length > 1
                  return (
                    <div className={classes.productCardStyle} key={u.update.id}>
                      <DownloadZipCard
                        update={u.update}
                        updates={u.updates}
                        classes={classes}
                        downloadToken={downloadToken}
                        hasPrevious={hasPrevious}
                        updatePreviousVersions={() => {
                          setPreviousVersions(updatesPrevious ? updatesPrevious.allVersions : [])
                          setPreviousVersionsVisible(true)
                        }}
                      />
                    </div>
                  )
                } else {
                  let updatesPrevious = allUpdates.filter(v => v.baseProductId === u.baseProductId)[0]
                  let hasPrevious = updatesPrevious && updatesPrevious.allVersions.length > 1

                  return (
                    <div className={classes.productCardStyle} key={u.update.id}>
                      <DownloadCard
                        update={u.update}
                        classes={classes}
                        downloadToken={downloadToken}
                        hasPrevious={hasPrevious}
                        updatePreviousVersions={() => {
                          setPreviousVersions(updatesPrevious ? updatesPrevious.allVersions : [])
                          setPreviousVersionsVisible(true)
                        }}
                      />
                    </div>
                  )
                }
              })}
            </ReactGridLayout>
          ) : (
            <CenteredDiv sx={{ marginTop: '15px' }}>{localizedStrings.noDownloads}</CenteredDiv>
          )}
        </div>
      </CenteredDiv>
    </>
  )
}
