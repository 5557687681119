import { useMsal } from '@azure/msal-react'
import { PictureAsPdfOutlined } from '@mui/icons-material'
import { getResourceUrl, RegularButton, useWtxLocalization } from '@wavetronix/common-components'
import React from 'react'
import { useSnackbar } from 'notistack'
import { env } from '../index.js'
import { getDeviceName } from '../utils/productTypeHash'
import downloadNotes from '../utils/downloadNotes'
import exists from '../utils/exists'
import { productImageHash } from '../utils/productTypeHash'
import JSZip from 'jszip'
import SoulApi from '../api/SoulApi'

export default function DownloadZipCard({ update, updates, classes, downloadToken, hasPrevious, updatePreviousVersions }) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  let localizedStrings = useWtxLocalization()

  const handleDownload = async updates => {
    if (updates.length > 0) {
      const zip = new JSZip()

      // Fetch files and add to zip

      var downloadResults = updates.map(async (u, index) => {
        var res = await SoulApi.downloadUpdate(instance, accounts, u.id)
          .then(data => {
            return { fileName: `${u.itemNumber}.${u.version}.wxdp`, data: data }
          })
          .catch(err => console.log(err))

        return res
      })

      Promise.allSettled(downloadResults).then(results => {
        results.map(res => zip.file(`${res.value.fileName}`, res.value.data))

        // Generate zip file
        zip.generateAsync({ type: 'blob' }).then(content => {
          // Create a download link
          const downloadLink = document.createElement('a')
          downloadLink.href = URL.createObjectURL(content)
          downloadLink.download = `${updates[0].itemNumber}.${updates[0].version}.zip`
          downloadLink.click()
        })
      })
    }
  }

  const handleDownloadNotes = async updates => {
    if (updates.length > 0) {
      updates.map(u => downloadNotes(instance, accounts, u.id, `${u.itemNumber}${u.version}`, enqueueSnackbar))
    }
  }

  return (
    <>
      <div className={classes.imageContainer}>
        <img
          className={classes.imageStyle}
          alt={`${update.productName}`}
          src={`${getResourceUrl(env.runMode)}/images/products/${productImageHash(update.baseProductId)}`}
        />
      </div>
      <div className={classes.infoBlockStyle}>
        <h4 className={classes.productName}>{getDeviceName(update.baseProductId)}</h4>

        <div style={{ marginTop: 10 }}>
          <RegularButton
            id={`${update.baseProductId}DownloadButton`}
            onClick={async () => {
              handleDownload(updates)
            }}
            value={downloadToken}
            className={classes.softwareButtonStyle}
          >
            <div
              style={{
                fontSize: '15px'
              }}
            >
              {localizedStrings.getLatest} - {update.version}
            </div>
          </RegularButton>
        </div>
        <div>
          <RegularButton
            id={`${update.baseProductId}ReleaseNotesButton`}
            disabled={!exists(update.releaseNoteLastModified)}
            onClick={async () => await handleDownloadNotes(updates, enqueueSnackbar)}
            className={classes.releaseNotesButtonStyle}
          >
            <PictureAsPdfOutlined />
            <div style={{ marginLeft: '15px', fontSize: '15px' }}>{localizedStrings.releaseNotes}</div>
          </RegularButton>
        </div>
        <div>
          <RegularButton
            id={`${update.baseProductId}UpdatePreviousVersionsButton`}
            disabled={!hasPrevious}
            onClick={updatePreviousVersions}
            className={classes.releaseNotesButtonStyle}
          >
            <div style={{ marginLeft: '15px', fontSize: '15px' }}>{localizedStrings.previousVersions}</div>
          </RegularButton>
        </div>
      </div>
    </>
  )
}
