import { useMsal } from '@azure/msal-react'
import { PictureAsPdfOutlined } from '@mui/icons-material'
import { getResourceUrl, RegularButton, useWtxLocalization } from '@wavetronix/common-components'
import React from 'react'
import { useSnackbar } from 'notistack'
import { env } from '../index.js'
import { getDeviceName } from '../utils/productTypeHash'
import downloadNotes from '../utils/downloadNotes'
import exists from '../utils/exists'
import { productImageHash } from '../utils/productTypeHash'

export default function DownloadCard({ update, classes, downloadToken, hasPrevious, updatePreviousVersions }) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  let localizedStrings = useWtxLocalization()

  return (
    <>
      <div className={classes.imageContainer}>
        <img
          className={classes.imageStyle}
          alt={`${update.productName}`}
          src={`${getResourceUrl(env.runMode)}/images/products/${productImageHash(update.baseProductId)}`}
        />
      </div>
      <div className={classes.infoBlockStyle}>
        <h4 className={classes.productName}>{getDeviceName(update.baseProductId)}</h4>

        <div style={{ marginTop: 10 }}>
          <form action={update.url} method='post'>
            <RegularButton
              id={`${update.baseProductId}GetLatestButton`}
              type='submit'
              name='token'
              value={downloadToken}
              className={classes.softwareButtonStyle}
            >
              <div
                style={{
                  fontSize: '15px'
                }}
              >
                {localizedStrings.getLatest} - {update.version}
              </div>
            </RegularButton>
          </form>
        </div>
        <div>
          <RegularButton
            id={`${update.baseProductId}ReleaseNotesButton`}
            disabled={!exists(update.releaseNoteLastModified)}
            onClick={() => downloadNotes(instance, accounts, update.id, update.version, enqueueSnackbar)}
            className={classes.releaseNotesButtonStyle}
          >
            <PictureAsPdfOutlined />
            <div style={{ marginLeft: '15px', fontSize: '15px' }}>{localizedStrings.releaseNotes}</div>
          </RegularButton>
        </div>
        <div>
          <RegularButton
            id={`${update.baseProductId}PreviousVersionsButton`}
            disabled={!hasPrevious}
            onClick={updatePreviousVersions}
            className={classes.releaseNotesButtonStyle}
          >
            <div style={{ marginLeft: '15px', fontSize: '15px' }}>{localizedStrings.previousVersions}</div>
          </RegularButton>
        </div>
      </div>
    </>
  )
}
