export const localEnv = {
  wavetronix: {
    windowURL: 'mysoftware.wtxdev.com',

    runMode: 'dev',
    clientId: 'a992ba78-62e7-4d4f-9207-fb1d71435b53',
    urls: {
      updatesURL: 'https://wt-updatesmanagement-rest.wtxdev.com'
      // updatesURL: 'http://localhost:5002'
    },
    basicAuthentication: {}
  },
  islandradar: {
    windowURL: 'mysoftware.islandradardev.com',
    runMode: 'dev',
    clientId: 'a992ba78-62e7-4d4f-9207-fb1d71435b53',
    urls: {
      updatesURL: 'https://wt-updatesmanagement-rest.wtxdev.com/islandradar'
      // updatesURL: 'http://localhost:5002/islandradar'
    },
    basicAuthentication: {}
  },
  fathom: {
    windowURL: 'mysoftware.fathomradar.com',
    runMode: 'prod',
    clientId: '22b40948-eab6-4ea0-b26b-ce326156b26d',
    urls: {
      updatesURL: 'https://wt-updatesmanagement-rest.wavetronix.com/fathom'
      // updatesURL: 'http://localhost:5002/fathom'
    },
    basicAuthentication: {}
  }
}
