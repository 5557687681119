import { useWtxLocalization, SlideUpDialog } from '@wavetronix/common-components'
import React from 'react'
import UpdatesList from './UpdatesList'

export default function PreviousVersionsList(props) {
  let localizedStrings = useWtxLocalization()

  function onClose() {
    props.onClose()
  }

  return (
    <SlideUpDialog
      id='previousVersionsListModal'
      fullScreen={true}
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: '0px' }}>{localizedStrings.previousVersions}</h3>}
    >
      <UpdatesList
        updates={props.data}
        loading={props.isLoading}
        error={props.error}
        refetch={props.refetch}
        styles={{ padding: '10px 0px 0px 10px' }}
      />
    </SlideUpDialog>
  )
}
